import React from "react"
import "./text-section.scss"

const TextSection = props => (
  <section className={"text-section" + (props.top ? " mb30" : null)}>
    <div className="container center">
      <div
        dangerouslySetInnerHTML={{
          __html: props.content,
        }}
      />
    </div>
  </section>
)

export default TextSection

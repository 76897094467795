import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import "./cta-section.scss"

const CTASection = props => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "toure-bookshelf.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag="section"
      className="cta-section"
      fluid={data.background.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <div className="container">
        <div className="cta-wrapper">
          <div className="cta-content">
            <h2>Let's Work Together</h2>
            <h3>Writing, Speaking, Hosting</h3>
            <p>
              I would really like to hear from you! I am available to talk at
              your next event. The fastest way to contact me is by email. I
              receive many messages per day, so be patient, I will get back to
              you as soon as I can.
            </p>
            <a
              href="mailto:toureshow@gmail.com?subject=Toure%20Website%20Inquiry"
              className="button"
            >
              Contact Me
            </a>
            {/* <Link to="/contact-us" className="button">
              Contact Me
            </Link> */}
          </div>
          <Img
            fluid={data.background.childImageSharp.fluid}
            className="cta-image"
            alt="Toure reading with his children"
          />
        </div>
      </div>
    </BackgroundImage>
  )
}

export default CTASection

import React from "react"
import Img from "gatsby-image"
import "./promo-section.scss"
import ApplePodcast from "././svgs/apple-podcasts"
import Spotify from "././svgs/spotify"
import Amazon from "././svgs/amazon"

const PromoSection = props => {
  const data = props.content
  return (
    <section className="promo-section">
      <div className="container">
        <div className="promo-wrapper">
          <div className="promo-image-container">
            <Img
              fluid={data.image.src.childImageSharp.fluid}
              className="promo-image"
              alt={data.image.alt}
            />
          </div>
          <div className="promo-content-wrapper">
            <div className="promo-content-header">
              <div className="">
                <h2>{data.title}</h2>
                {data.subtitle && <h3>{data.subtitle}</h3>}
              </div>
              <ul className="promo-icon-links">
                {data.links.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.type === "amazon" ? (
                        <Amazon className="icon icon-large" />
                      ) : link.type === "apple" ? (
                        <ApplePodcast className="icon" />
                      ) : (
                        <Spotify className="icon" />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <p>{data.description}</p>
            <a
              href={data.links[0].url}
              className="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.links.length > 1 ? "Listen to Podcast" : "Order Book"}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PromoSection

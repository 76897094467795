import React from "react"
import { graphql } from "gatsby"
import Layout from "./components/layout/layout"
import SEO from "./components/seo"
import TextSection from "./components/text-section/text-section"
import PromoSection from "./components/promo-section/promo-section"
import CTASection from "./components/cta-section/cta-section"
import HeroLanding from "./components/hero/hero-landing"

const IndexPage = props => {
  const data = props.data
  const sectionA = "<p>My broadcast career has spanned major networks and cable outlets both as a host and contributor: I hosted The Cycle on MSNBC, Hip Hop Shop on Fuse, the Black Carpet on BET, I’ll Try Anything Once on Treasure HD, and Spoke N Heard on MTV. I was also the first Pop Culture correspondent on CNN.</p><p>A respected voice in the community, I consistently lend it to amplify causes for social justice and political awareness.</p>";
  const sectionB =
    "<p>I’ve published six books including I Would Die 4 U: Why Prince Became An Icon and Who’s Afraid of Post-Blackness: A Look At What It Means To Be Black Now. I host the podcast Toure Show — guests have included Zadie Smith, Malcolm Gladwell, Ice Cube, Spike Lee, Kareem Abdul-Jabbar, Nikki Giovanni, Toni Braxton, Taraji P. Henson, and many others. I love using media to help challenge people’s perception of themselves and of the world.</p>"
  const sectionC =
    "<p>I love to talk about culture, politics, and the direction this country is going. I care deeply about America, pop culture, and my family—I have a wife, a son, and a daughter. I started out writing about music for <a href='https://www.rollingstone.com/author/tour/' target='_blank' rel='noopener noreferrer'>Rolling Stone</a>, as a Contributing Editor I did cover stories on <a href='https://www.rollingstone.com/music/music-news/the-book-of-jay-2-103299/' target='_blank' rel='noopener noreferrer'>Jay-Z</a>, <a href='https://www.rollingstone.com/music/music-features/beyonce-talks-fame-relationships-starting-a-family-becoming-sasha-fierce-111695/' target='_blank' rel='noopener noreferrer'>Beyonce</a>, <a href='https://www.rollingstone.com/music/music-news/the-mystery-of-lauryn-hill-249020/' target='_blank' rel='noopener noreferrer'>Lauryn Hill</a>, Snoop Dogg, <a href='https://www.rollingstone.com/music/music-features/migos-high-times-and-heartache-with-the-three-kings-of-hip-hop-122262/'>the Migos</a>, Adele, and many others. I segued into TV as a pop culture correspondent for CNN, then hosted shows for MTV2, BET, and Fuse, then became the host of The Cycle on MSNBC.</p>"
  return (
    <Layout>
      <SEO title="Home" />
      <HeroLanding />
      <TextSection content={sectionA} top/>
      <section className="text-section text-section-intro">
        <div className="container center">
          <h3 className="white">
            I write because it’s the most natural thing in the world to me. I
            write because I love making sentences. I write because I live to
            talk about the world in ways that could challenge how people think.
            I write because I love talking about Black people and Black culture
            and the beauty of Blackness.
          </h3>
        </div>
      </section>
      <TextSection content={sectionB} />
      <PromoSection content={data.die4u} />
      <PromoSection content={data.postBlackness} />
      <TextSection content={sectionC} />
      <PromoSection content={data.toureShow} />
      <CTASection />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    toureShow: toureShowJson {
      title
      description
      image {
        alt
        src {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      links {
        type
        url
      }
    }
    postBlackness: postBlacknessJson {
      title
      subtitle
      description
      image {
        alt
        src {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      links {
        type
        url
      }
    }
    die4u: die4UJson {
      title
      subtitle
      description
      image {
        alt
        src {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      links {
        type
        url
      }
    }
  }
`

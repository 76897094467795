import React from "react"
import Img from "gatsby-image"
import "./hero.scss"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const HeroLanding = props => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "toure-mic-full.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag="div"
      className="hero-section"
      fluid={data.image.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <div className="container mv0">
        <div className="hero-wrapper">
          <div className="hero-content">
            <h4>Author</h4>
            <h4>Podcaster</h4>
            <h4>TV Host</h4>
            <h4 className="yellow">Brooklynite</h4>
          </div>
          <div className="hero-divider"></div>
          <Img
            fluid={data.image.childImageSharp.fluid}
            className="hero-image"
          />
        </div>
      </div>
    </BackgroundImage>
  )
}

export default HeroLanding

import React from "react"

const Amazon = props => {
  return (
    <svg
      width="1000"
      height="301.354"
      viewBox="0 0 1000 301.35401"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="amazon"
      role="img"
      className={props.className}
    >
      <title id="amazon">Amazon Icon</title>
      <path
        d="m 620.38044,235.66818 c -58.11169,42.83292 -142.34105,65.68583 -214.86092,65.68583 -101.68461,0 -193.22683,-37.60937 -262.48206,-100.16107 -5.44116,-4.91885 -0.5659,-11.62235 5.96353,-7.79179 74.7399,43.48586 167.15276,69.64701 262.61265,69.64701 64.37988,0 135.20216,-13.32001 200.32209,-40.96113 9.83763,-4.17885 18.06468,6.44234 8.44471,13.58115"
        fill="#E3D3D0"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 644.53924,208.02705 c -7.40002,-9.4894 -49.10114,-4.48353 -67.81875,-2.26353 -5.70237,0.69648 -6.57292,-4.26588 -1.43649,-7.83531 33.21291,-23.37526 87.71169,-16.6282 94.06697,-8.79293 6.35527,7.87883 -1.65411,62.5082 -32.86468,88.58226 -4.78823,4.00469 -9.35881,1.87178 -7.22586,-3.43882 7.00822,-17.49881 22.72234,-56.71874 15.27881,-66.25167"
        fill="#E3D3D0"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 578.02637,32.908411 0,-22.722322 c 0,-3.4388179 2.61176,-5.7458719 5.74588,-5.7458719 l 101.72809,0 c 3.26472,0 5.87648,2.350589 5.87648,5.7458719 l 0,19.457626 c -0.0435,3.264696 -2.78588,7.530573 -7.66119,14.277622 l -52.71404,75.262273 c 19.58823,-0.47879 40.26468,2.43764 58.02466,12.4494 4.00469,2.26352 5.09293,5.57175 5.39763,8.83648 l 0,24.24586 c 0,3.30822 -3.65647,7.1823 -7.48705,5.17997 -31.29762,-16.41058 -72.86816,-18.19528 -107.474,0.17413 -3.52587,1.9153 -7.22586,-1.91528 -7.22586,-5.22353 l 0,-23.02702 c 0,-3.69999 0.0435,-10.01178 3.74349,-15.62706 l 61.07171,-87.581077 -53.14934,0 c -3.2647,0 -5.87646,-2.307035 -5.87646,-5.702352"
        fill="#E3D3D0"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 206.93856,174.68354 -30.94937,0 c -2.95998,-0.21762 -5.31057,-2.43762 -5.52823,-5.26706 l 0,-158.838596 c 0,-3.1776439 2.6553,-5.7023669 5.96352,-5.7023669 l 28.85996,0 c 3.00356,0.1305 5.39764,2.437637 5.61531,5.3105719 l 0,20.763532 0.56588,0 C 218.9962,10.88256 233.14323,1.5237471 252.2091,1.5237471 c 19.37056,0 31.47173,9.3588129 40.1776,29.4258739 7.48705,-20.067061 24.50702,-29.4258739 42.74584,-29.4258739 12.97175,0 27.16232,5.354111 35.82467,17.3682159 9.7941,13.363512 7.79174,32.777604 7.79174,49.797606 l -0.0435,100.248121 c 0,3.17763 -2.6553,5.74585 -5.96352,5.74585 l -30.90587,0 c -3.09056,-0.21762 -5.57173,-2.69879 -5.57173,-5.74585 l 0,-84.185796 c 0,-6.703515 0.60937,-23.418792 -0.8706,-29.774097 -2.30705,-10.664664 -9.22821,-13.66822 -18.19529,-13.66822 -7.48705,0 -15.32232,5.00588 -18.49997,13.015301 -3.17765,8.009403 -2.87294,21.416417 -2.87294,30.427016 l 0,84.185796 c 0,3.17763 -2.65529,5.74585 -5.96353,5.74585 l -30.90582,0 c -3.13412,-0.21762 -5.57177,-2.69879 -5.57177,-5.74585 l -0.0435,-84.185796 c 0,-17.716441 2.91643,-43.790545 -19.06586,-43.790545 -22.24353,0 -21.37294,25.421136 -21.37294,43.790545 l 0,84.185796 c 0,3.17763 -2.6553,5.74585 -5.96352,5.74585"
        fill="#E3D3D0"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 778.9579,1.5237471 c 45.92348,0 70.77872,39.4376019 70.77872,89.5834349 0,48.448168 -27.46705,86.884588 -70.77872,86.884588 -45.09643,0 -69.647,-39.4376 -69.647,-88.582238 0,-49.449363 24.85527,-87.8857849 69.647,-87.8857849 m 0.26116,32.4293949 c -22.80937,0 -24.24586,31.079935 -24.24586,50.450525 0,19.414093 -0.3047,60.854033 23.9847,60.854033 23.98467,0 25.11645,-33.43052 25.11645,-53.802307 0,-13.407014 -0.56589,-29.425821 -4.61414,-42.136414 -3.48235,-11.056426 -10.40349,-15.365837 -20.24115,-15.365837"
        fill="#E3D3D0"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 909.2848,174.68354 -30.81878,0 c -3.0906,-0.21762 -5.57177,-2.69879 -5.57177,-5.74585 l -0.0435,-158.882188 c 0.26118,-2.9164689 2.82943,-5.1799849 5.96353,-5.1799849 l 28.68587,0 c 2.69879,0.1305 4.91879,1.958823 5.52823,4.439997 l 0,24.2893669 0.56585,0 C 922.2566,11.88374 934.40125,1.5237471 955.77417,1.5237471 c 13.88587,0 27.42351,5.005884 36.12938,18.7176239 C 1000,32.951945 1000,54.324878 1000,69.690715 l 0,99.986975 c -0.34822,2.78585 -2.91647,5.00585 -5.96349,5.00585 l -31.03645,0 c -2.82943,-0.21762 -5.18002,-2.30703 -5.48469,-5.00585 l 0,-86.275201 c 0,-17.368215 2.00234,-42.789384 -19.37059,-42.789384 -7.53056,0 -14.45174,5.049433 -17.89056,12.710576 -4.35292,9.707072 -4.91881,19.37059 -4.91881,30.078808 l 0,85.535201 c -0.0435,3.17763 -2.74234,5.74585 -6.05056,5.74585"
        fill="#E3D3D0"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 496.93116,98.811876 c 0,12.057624 0.3047,22.112904 -5.78941,32.821134 -4.91881,8.7059 -12.75409,14.06 -21.41642,14.06 -11.88352,0 -18.84822,-9.05413 -18.84822,-22.41764 0,-26.378783 23.63643,-31.167007 46.05405,-31.167007 l 0,6.703513 m 31.21056,75.436374 c -2.04586,1.82823 -5.00589,1.95881 -7.31293,0.74001 -10.27295,-8.53175 -12.1447,-12.49291 -17.75998,-20.6329 -16.97646,17.32467 -29.03411,22.50465 -51.01643,22.50465 -26.07409,0 -46.31522,-16.06234 -46.31522,-48.2305 0,-25.11646 13.58118,-42.223499 32.99527,-50.581131 16.80232,-7.400017 40.26461,-8.705874 58.19873,-10.751751 l 0,-4.004701 c 0,-7.356485 0.56589,-16.062359 -3.78705,-22.417613 -3.74352,-5.702369 -10.96942,-8.052955 -17.36822,-8.052955 -11.79647,0 -22.28702,6.050579 -24.85527,18.587033 -0.52235,2.785898 -2.56821,5.528247 -5.39765,5.658834 L 415.53126,53.846031 C 413.00654,53.28018 410.17715,51.23427 410.91715,47.360188 417.79479,10.969646 450.703,2.1708008e-4 480.12882,2.1708008e-4 c 15.06117,0 34.73643,4.00471001992 46.61995,15.40939391992 15.06114,14.059982 13.62469,32.821137 13.62469,53.236424 l 0,48.230525 c 0,14.4953 6.00705,20.85055 11.66586,28.68587 1.95885,2.78585 2.39414,6.13761 -0.13071,8.22702 -6.31175,5.26708 -17.54234,15.06118 -23.7235,20.54587 l -0.0435,-0.0872"
        fill="#E3D3D0"
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="m 91.194013,98.811876 c 0,12.057624 0.304675,22.112904 -5.789406,32.821134 -4.918845,8.7059 -12.710593,14.06 -21.416467,14.06 -11.883518,0 -18.804673,-9.05413 -18.804673,-22.41764 0,-26.378783 23.636433,-31.167007 46.010546,-31.167007 l 0,6.703513 m 31.210537,75.436374 c -2.04587,1.82823 -5.0059,1.95881 -7.31295,0.74001 -10.2729,-8.53175 -12.10116,-12.49291 -17.759972,-20.6329 C 80.355174,171.68003 68.341102,176.86001 46.315217,176.86001 20.284648,176.86001 -2.141151e-6,160.79767 -2.141151e-6,128.62951 -2.141151e-6,103.51305 13.624684,86.406011 32.995241,78.048379 49.79757,70.648362 73.259897,69.342505 91.194013,67.296628 l 0,-4.004701 c 0,-7.356485 0.565884,-16.062359 -3.743527,-22.417613 -3.78708,-5.702369 -11.012928,-8.052955 -17.368233,-8.052955 -11.796431,0 -22.330559,6.050579 -24.898786,18.587033 -0.522364,2.785898 -2.568242,5.528247 -5.354107,5.658834 L 9.7941042,53.846031 C 7.2693961,53.28018 4.4835138,51.23427 5.1799844,47.360188 12.101156,10.969646 44.965811,2.1708008e-4 74.391681,2.1708008e-4 c 15.061131,0 34.736399,4.00471001992 46.619919,15.40939391992 15.06117,14.059982 13.62468,32.821137 13.62468,53.236424 l 0,48.230525 c 0,14.4953 6.00708,20.85055 11.6659,28.68587 2.00234,2.78585 2.43763,6.13761 -0.0871,8.22702 -6.31175,5.26708 -17.54231,15.06118 -23.72351,20.54587 l -0.087,-0.0872"
        fill="#E3D3D0"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  )
}

export default Amazon
